import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table, Select, Tree, Spin } from 'antd';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { debounce } from 'lodash';

import { useFilterData } from './hooks/useFilterData';
import { useProductsFetch } from './hooks/useProductsFetch';
import { createOrderBy } from './utils';
import { COLUMNS, SOURCE_OPTIONS } from './constants';
import TableHeader from 'Components/Table2.0/Components/Header';
import LoadingIcon from 'Components/LoadingIcon';
import { ProductItem as ProductItemType } from 'ProductListingTypes';
import ProductItem from './ProductItem';
import DatePicker from 'Components/DatePicker';
import { ReactComponent as IconGrid } from 'Assets/images/icons/grid.svg';
import { ReactComponent as IconListView } from 'Assets/images/icons/listView.svg';
import { ReactComponent as IconSort } from 'Assets/images/icons/sorter.svg';

import './index.scss';

const { Option } = Select

// Define allowed keys for type safety - loosen type to avoid strict checks
type FilterKeyType = string;

const ProductListing = () => {
  // Get filter data and URL management
  const {
    brandFilterList,
    supplierFilterList,
    categoryList,
    isLoading: isFilterLoading,
    fetchFilterData,
    getFiltersFromURL,
    updateURLParams,
  } = useFilterData()

  // Get product data management
  const {
    productList,
    totalCount,
    currentPage,
    isLoading: isProductsLoading,
    initProductList,
    loadMore,
    fetchPage,
  } = useProductsFetch()

  // Local states
  const [filters, setFilters] = useState(getFiltersFromURL())
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 16,
  })
  const [toggle, setToggle] = useState(true)
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([])

  const SORTABLE_COLUMNS = ['product_name', 'revenue', 'sold_item', 'customers', 'gross_margin']

  const sortedColumns = useMemo(
    () =>
      COLUMNS.map((column) => ({
        ...column,
        sorter: SORTABLE_COLUMNS.includes(column.dataIndex), // Chỉ cho phép sort các cột được định nghĩa
        sortOrder:
          SORTABLE_COLUMNS.includes(column.dataIndex) && filters.sortField === column.dataIndex
            ? filters.sortOrder
            : null,
      })),
    [filters.sortField, filters.sortOrder]
  )

  // Init effect
  useEffect(() => {
    fetchFilterData(filters.dataSource)
    initProductList(createFetchParams(filters, pagination))
  }, [])

  // Effect to fetch products when filters/pagination change
  useEffect(() => {
    fetchPage(createFetchParams(filters, pagination))
    updateURLParams(filters)
  }, [filters, pagination])

  // Helper function to create fetch params
  const createFetchParams = (filters: any, pagination: any) => ({
    category_id: filters.cateId,
    source: filters.dataSource,
    page: pagination.current,
    per_page: pagination.pageSize,
    limit: 16,
    brands: filters.brand,
    suppliers: filters.supplier,
    from_date: filters.from_date,
    to_date: filters.to_date,
    keyword: filters.keyWord,
    order_by: createOrderBy(filters.sortField, filters.sortOrder),
  })

  // Handlers
  const handleFilterChange = useCallback((key: FilterKeyType, value: any) => {
    if (key === 'dataSource' && typeof value === 'string') {
      // Khi thay đổi dataSource (Content Source), cần xử lý đặc biệt
      
      // 1. Trước tiên load dữ liệu danh mục mới
      fetchFilterData(value);
      
      // 2. Sau đó cập nhật filters và reset các filter liên quan
      setFilters(prev => {
        const newFilters = { 
          ...prev, 
          dataSource: value,
          cateId: 'root', // Reset to root category
          keyWord: '',    // Clear search keyword 
        };
        
        // 3. Reset expanded keys
        setExpandedKeys([]);
        
        // 4. Load lại product list với filters mới
        setTimeout(() => {
          initProductList(createFetchParams(newFilters, { current: 1, pageSize: 16 }));
        }, 0);
        
        return newFilters;
      });
      
      // 5. Reset pagination
      setPagination(prev => ({ ...prev, current: 1 }));
      
      return; // Đã xử lý xong, không cần chạy code bên dưới
    }
    
    // Xử lý các trường hợp thông thường
    setFilters((prev) => {
      const newFilters = { ...prev, [key]: value };
      
      // Reset pagination when any filter (except page) changes
      if (key !== 'page') {
        setPagination((prev) => ({ ...prev, current: 1 }));
      }
      
      return newFilters;
    });
  }, [fetchFilterData, initProductList, createFetchParams]); // Add dependencies

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      handleFilterChange('keyWord', value)
      setPagination((prev) => ({
        ...prev,
        current: 1,
      }))
    }, 500), // Tăng delay lên 500ms
    []
  )

  // State local để handle input value
  const [searchValue, setSearchValue] = useState('')
  const handleSearch = (value: string) => {
    setSearchValue(value) // Update local state ngay lập tức
    debouncedSearch(value) // Debounce việc update filter và call API
  }

  const findPath = useCallback((tree: any[], key: string, path: string[] = []): string[] | null => {
    for (const node of tree) {
      if (node.key === key) {
        return [...path, node.key]
      }
      if (node.children) {
        const foundPath = findPath(node.children, key, [...path, node.key])
        if (foundPath) {
          return foundPath
        }
      }
    }
    return null
  }, [])

  // Chỉ expand một lần khi init hoặc reload page
  useEffect(() => {
    if (filters.cateId && categoryList.length > 0) {
      const path = findPath(categoryList, filters.cateId) || []
      setExpandedKeys(path)
    }
  }, [categoryList]) // Chỉ phụ thuộc vào categoryList

  const handleTableChange = (tablePagination: any, tableFilters: any, sorter: any) => {
    setPagination({
      current: tablePagination.current,
      pageSize: tablePagination.pageSize,
    })

    if (sorter.field && SORTABLE_COLUMNS.includes(sorter.field)) {
      if (sorter.order !== filters.sortOrder || sorter.field !== filters.sortField) {
        handleFilterChange('sortField', sorter.field)
        handleFilterChange('sortOrder', sorter.order)
        // Reset to page 1 when sort changes
        setPagination((prev) => ({
          ...prev,
          current: 1,
        }))
      }
    }
  }

  // Infinite scroll setup
  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isProductsLoading,
    hasNextPage: totalCount > currentPage * pagination.pageSize,
    onLoadMore: () => loadMore(createFetchParams(filters, pagination)),
    disabled: false,
    rootMargin: '0px 0px 400px 0px',
  })

  // Render methods
  const renderTableHeader = () => (
    <div className="flex justify-between items-center mb-4">
      <div className="flex-1">
        <TableHeader
          search={{
            keyword: searchValue,
            onSearch: handleSearch,
          }}
          // dropdown={{
          //   fields: [
          //     {
          //       label: 'Brands',
          //       value: 'brands',
          //       options: brandFilterList,
          //       type: 'DROPDOWN',
          //       onClick: ({ value }) => handleFilterChange('brand', value.toString()),
          //     },
          //     {
          //       label: 'Suppliers',
          //       value: 'suppliers',
          //       options: supplierFilterList,
          //       type: 'DROPDOWN',
          //       onClick: ({ value }) => handleFilterChange('supplier', value.toString()),
          //     },
          //   ],
          //   value: {
          //     brands: filters.brand,
          //     suppliers: filters.supplier,
          //   },
          // }}
        />
      </div>
      <div className="flex items-center">
        <Select
          className="w-[200px] h-full text-base rounded-md text-primary_text "
          value={filters.dataSource}
          onChange={(value) => handleFilterChange('dataSource', value)}
          placeholder="Content Source"
        >
          {SOURCE_OPTIONS.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
        <div className={`iconProductListing ${filters.view == 'list' && 'active'}`}>
          <div onClick={() => handleFilterChange('view', 'list')} className="p-2 ml-2 rounded">
            <IconListView />
          </div>
        </div>
        <div className={`iconProductListing ${filters.view == 'grid' && 'active'}`}>
          <div onClick={() => handleFilterChange('view', 'grid')} className="p-2 mr-2 rounded ">
            <IconGrid />
          </div>
        </div>

        <DatePicker
          onChange={({ from_date, to_date }) => {
            handleFilterChange('from_date', from_date)
            handleFilterChange('to_date', to_date)
          }}
          value={{ from_date: filters.from_date, to_date: filters.to_date }}
        />
      </div>
    </div>
  )

  const renderProductGrid = () => (
    <div className="flex-1 overflow-scroll Products-grid-container" ref={rootRef}>
      <div className="Products-grid-title">Product Info</div>
      <div className="grid flex-1 Products-grid">
        {productList.map((item, index) => (
          <ProductItem product={item} key={`${item.barcode}-${index}`} />
        ))}
      </div>
      {!isProductsLoading && totalCount > currentPage * pagination.pageSize && (
        <div ref={sentryRef}>
          <LoadingIcon />
        </div>
      )}
    </div>
  )

  const renderProductTable = () => (
    <Table
      pagination={{
        ...pagination,
        total: totalCount,
        showSizeChanger: true,
        showTotal: (total) => `Total ${total} items`,
      }}
      loading={isProductsLoading}
      columns={sortedColumns}
      dataSource={productList}
      rowKey={(record: ProductItemType) => record.barcode || ''}
      onChange={handleTableChange}
      scroll={{ x: 2300 }}
      onRow={(record: ProductItemType) => {
        return {
          onClick: () => {
            window.open(`/analytics/product360/${record?.barcode}`, '_blank')
          },
        }
      }}
    />
  )

  const renderCategoryTreeSection = () => (
    <>
      {/* Toggle Button */}
      <div style={{ backgroundColor: '#f5f5f5' }}>
        <div
          className="items-center justify-center bg-white cursor-pointer"
          style={{
            display: toggle ? 'none' : 'flex',
            width: 24,
            height: 40,
            fontSize: 18,
            transform: 'translateX(-33px)',
            borderRadius: '0 4px 4px 0',
            top: 255,
            position: 'absolute',
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
          }}
          onClick={() => setToggle(true)}
        >
          {`>`}
        </div>
      </div>

      {/* Category Tree */}
      <div
        className="relative bg-white left-corner-radius"
        style={{ width: 304, display: toggle ? '' : 'none' }}
      >
        <div
          style={{ height: 50, background: 'var(--table_header)' }}
          className="flex justify-between p-4 text-sm border-b top-left-corner-radius text-secondary_text"
        >
          <div className="flex">
            Category
            <IconSort className="ml-2 cursor-pointer" />
          </div>
          <div style={{ cursor: 'pointer', fontSize: 18 }} onClick={() => setToggle(false)}>
            {`<`}
          </div>
        </div>

        <Spin spinning={isFilterLoading}>
          <div className="p-4">
            <Tree
              treeData={categoryList}
              selectedKeys={filters.cateId ? [filters.cateId] : []}
              expandedKeys={expandedKeys}
              onExpand={(expanded) => setExpandedKeys(expanded)}
              onSelect={(checkedKeys) => {
                handleFilterChange('cateId', checkedKeys[0])
                handleFilterChange('keyWord', '')
                setPagination((prev) => ({
                  ...prev,
                  current: 1,
                }))
              }}
            />
          </div>
        </Spin>
      </div>
    </>
  )

  return (
    <div className="ProductListing">
      {renderTableHeader()}

      {/* Category Tree Section */}
      <div className="flex">
        {renderCategoryTreeSection()}
        <div className={`flex flex-1 mr-1 rounded ${filters.view === 'grid' ? 'shadow-full' : ''}`}>
          {filters.view === 'grid' ? renderProductGrid() : renderProductTable()}
        </div>
      </div>
    </div>
  )
}

export default ProductListing
